.layout-topbar {
	position: fixed;
	height: 5rem;
	z-index: 997;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 2rem;
	background-color: var(--surface-card);
	transition: left $transitionDuration;
	display: flex;
	align-items: center;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
		0px 1px 4px rgba(0, 0, 0, 0.08);

	.layout-topbar-logo {
		display: flex;
		align-items: center;
		color: var(--surface-900);
		font-size: 1.5rem;
		width: 100%;
		border-radius: 12px;

		img {
			height: 3.5rem;
			width: 10rem;
			border-radius: 1.75rem;
		}

		&:hover {
			color: var(--text-color);
			text-decoration: none;
		}

		&:focus {
			@include focused();
			color: $colorPrimary;
			box-shadow: none;
		}
	}

	.layout-topbar-button {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		color: var(--text-color-secondary);
		border-radius: 12px;
		height: 3rem;
		cursor: pointer;
		// margin-left: 2rem;
		transition: background-color $transitionDuration;

		&-active {
			@include focused();
			color: $colorPrimary;
			box-shadow: none;
			font-weight: bold;
		}

		&:hover {
			color: var(--text-color);
			background-color: var(--surface-hover);
			text-decoration: none;
		}

		&:focus {
			@include focused();
			color: $colorPrimary;
			box-shadow: none;
			font-weight: bold;
		}

		&-right-most {
			margin-left: auto;
		}

		i {
			font-size: 1.5rem;
			margin-right: 0.5rem;
		}

		span {
			font-size: 1rem;
			display: inline-flex;
		}
	}

	.layout-topbar-menu-button {
		display: none;

		i {
			font-size: 1.25rem;
		}
	}

	.layout-topbar-menu {
		margin: 0;
		margin-left: auto;
		padding: 0;
		list-style: none;
		display: flex;

		.layout-topbar-button {
			margin-left: 1rem;
		}
	}
}

@media (max-width: 768px) {
	.layout-topbar {
		justify-content: flex-start;
		height: 3.5rem;
		padding: 0 1rem;

		.layout-topbar-logo {
			width: auto;

			img {
				height: 2.5rem;
				width: 7rem;
				border-radius: 1.75rem;
			}
		}

		.layout-topbar-menu-button {
			display: inline-flex;
		}

		.layout-topbar-menu {
			flex: 1;
			padding-left: 1rem;
			justify-content: space-between;

			&.layout-topbar-menu-mobile-active {
				display: block;
			}

			.layout-topbar-button {
				margin-left: 0;
				display: flex;
				flex-direction: column;
				width: 3rem;
				height: 3rem;
				justify-content: center;
				border-radius: 50%;
				padding: 1rem;

				i {
					font-size: 1rem;
					margin-right: 0;
				}

				span {
					font-size: 0.65rem;
					display: none;
				}
			}
		}
	}
}
