h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 1.5rem 0 1rem 0;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.2;
	color: inherit;

	&:first-child {
		margin-top: 0;
	}
}

h1 {
	font-size: 2.5rem;
	@media (max-width: 768px) {
		font-size: 1.5rem;
	}
}

h2 {
	font-size: 2rem;
	@media (max-width: 768px) {
		font-size: 1rem;
	}
}

h3 {
	font-size: 1.75rem;
	@media (max-width: 768px) {
		font-size: 1.25rem;
	}
}

h4 {
	font-size: 1.5rem;
	@media (max-width: 768px) {
		font-size: 1rem;
	}
}

h5 {
	font-size: 1.25rem;
	@media (max-width: 768px) {
		font-size: 0.75rem;
	}
}

h6 {
	font-size: 1rem;
	@media (max-width: 768px) {
		font-size: 0.5rem;
	}
}

mark {
	background: #fff8e1;
	padding: 0.25rem 0.4rem;
	border-radius: $borderRadius;
	font-family: monospace;
}

blockquote {
	margin: 1rem 0;
	padding: 0 2rem;
	border-left: 4px solid #90a4ae;
}

hr {
	border-top: solid var(--surface-border);
	border-width: 1px 0 0 0;
	margin: 1rem 0;
}

p {
	margin: 0 0 1rem 0;
	line-height: 1.5;

	&:last-child {
		margin-bottom: 0;
	}
	@media (max-width: 768px) {
		line-height: 1;
	}
}
