@import './_variables';
@import './sass/_layout';
@import './_overrides';

// Form css
.form .card {
	min-width: 450px;
}
.form .card form {
	margin-top: 2rem;
}
.form .card .p-field {
	margin-bottom: 2rem;

	.p-float-label input:-webkit-autofill ~ label {
		top: -0.75rem;
		font-size: 12px;
	}
}
@media screen and (max-width: 960px) {
	.form .card {
		width: 80%;
	}
}
@media screen and (max-width: 640px) {
	.form .card {
		width: 100%;
		min-width: 0;
	}
}

.p-menubar {
	font-weight: bold;
}

.p-menubar .p-menubar-button {
	margin-left: auto;
}

.p-menubar ul {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link,
.p-menubar
	.p-menubar-root-list
	> .p-menuitem.p-menuitem-active
	> .p-menuitem-link:not(.p-disabled):hover {
	background: transparent;
}
.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	> .p-menuitem-link:not(.p-disabled):hover {
	.p-menuitem-text {
		color: #a8d3f5;
		font-weight: bold;
	}
	.p-menuitem-icon {
		color: #a8d3f5;
		font-weight: bold;
	}
	background: transparent;
	font-weight: bold;
}

.p-menubar
	.p-menubar-root-list
	> .p-menuitem
	.p-menuitem-active
	> .p-menuitem-link {
	.p-menuitem-text {
		color: $colorPrimary;
		font-weight: bold;
	}
	.p-menuitem-icon {
		color: $colorPrimary;
		font-weight: bold;
	}
	box-shadow: none;
	font-weight: bold;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
	&:focus {
		.p-menuitem-text {
			color: $colorPrimary;
			font-weight: bold;
		}
		.p-menuitem-icon {
			color: $colorPrimary;
			font-weight: bold;
		}
		box-shadow: none;
		font-weight: bold;
	}
}

.p-chip.custom-chip {
	background: var(--primary-color);
	color: var(--primary-color-text);
}
.p-chip.success {
	background: #22c55e;
	color: var(--primary-color-text);
}

.custom-marker {
	display: flex;
	width: 2rem;
	height: 2rem;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 50%;
	z-index: 1;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
	line-height: 1;
}

@media screen and (max-width: 768px) {
	.customized-timeline .p-timeline-event:nth-child(even) {
		flex-direction: row !important;
	}

	.customized-timeline
		.p-timeline-event:nth-child(even)
		.p-timeline-event-content {
		text-align: left !important;
	}
	.customized-timeline .p-timeline-event-opposite {
		flex: 0;
	}
	.customized-timeline .p-card {
		margin-top: 1rem;
	}
}

.p-card {
	// background: #3babbd;
	border-radius: 0.75rem;
}

.contact-wrapper .p-button {
	margin-right: 0.5rem;
}
.contact-wrapper .p-buttonset .p-button {
	margin-right: 0;
}
.contact-wrapper .p-button i {
	line-height: 2.25rem;
}
.contact-wrapper .p-button.google {
	background: linear-gradient(
		to left,
		var(--purple-600) 50%,
		var(--purple-700) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	border-color: var(--purple-700);
}
.contact-wrapper .p-button.google:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.google i {
	background-color: var(--purple-700);
}
.contact-wrapper .p-button.google:focus {
	box-shadow: 0 0 0 1px var(--purple-400);
}
.contact-wrapper .p-button.youtube {
	background: linear-gradient(
		to left,
		var(--pink-600) 50%,
		var(--pink-700) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	border-color: var(--pink-700);
}
.contact-wrapper .p-button.youtube:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.youtube i {
	background-color: var(--pink-700);
}
.contact-wrapper .p-button.youtube:focus {
	box-shadow: 0 0 0 1px var(--pink-400);
}
.contact-wrapper .p-button.vimeo {
	background: linear-gradient(
		to left,
		var(--green-200) 50%,
		var(--green-300) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #000;
	border-color: var(--green-300);
}
.contact-wrapper .p-button.vimeo:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.vimeo i {
	background-color: var(--green-300);
}
.contact-wrapper .p-button.vimeo:focus {
	box-shadow: 0 0 0 1px var(--green-400);
}
.contact-wrapper .p-button.facebook {
	background: linear-gradient(
		to left,
		var(--indigo-600) 50%,
		var(--indigo-700) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	border-color: var(--indigo-700);
}
.contact-wrapper .p-button.facebook:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.facebook i {
	background-color: var(--indigo-700);
}
.contact-wrapper .p-button.facebook:focus {
	box-shadow: 0 0 0 1px var(--indigo-400);
}
.contact-wrapper .p-button.twitter {
	background: linear-gradient(
		to left,
		var(--blue-400) 50%,
		var(--blue-500) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	border-color: var(--blue-500);
}
.contact-wrapper .p-button.twitter:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.twitter i {
	background-color: var(--blue-500);
}
.contact-wrapper .p-button.twitter:focus {
	box-shadow: 0 0 0 1px var(--blue-200);
}
.contact-wrapper .p-button.linkedin {
	background: linear-gradient(to left, #036ec0 50%, #055a9b 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	border-color: #055a9b;
}
.contact-wrapper .p-button.linkedin:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.linkedin i {
	background-color: #055a9b;
}
.contact-wrapper .p-button.linkedin:focus {
	box-shadow: 0 0 0 1px var(--blue-200);
}
.contact-wrapper .p-button.slack {
	background: linear-gradient(
		to left,
		var(--orange-400) 50%,
		var(--orange-500) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	border-color: var(--orange-500);
}
.contact-wrapper .p-button.slack:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.slack i {
	background-color: var(--orange-500);
}
.contact-wrapper .p-button.slack:focus {
	box-shadow: 0 0 0 1px var(--orange-200);
}
.contact-wrapper .p-button.amazon {
	background: linear-gradient(
		to left,
		var(--yellow-400) 50%,
		var(--yellow-500) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #000;
	border-color: var(--yellow-500);
}
.contact-wrapper .p-button.amazon:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.amazon i {
	background-color: var(--yellow-500);
}
.contact-wrapper .p-button.amazon:focus {
	box-shadow: 0 0 0 1px var(--yellow-200);
}
.contact-wrapper .p-button.github {
	background: linear-gradient(
		to left,
		var(--bluegray-700) 50%,
		var(--bluegray-800) 50%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	border-color: var(--bluegray-800);
}
.contact-wrapper .p-button.github:hover {
	background-position: left bottom;
}
.contact-wrapper .p-button.github i {
	background-color: var(--bluegray-800);
}
.contact-wrapper .p-button.github:focus {
	box-shadow: 0 0 0 1px var(--bluegray-500);
}
@media screen and (max-width: 960px) {
	.contact-wrapper .p-button {
		margin-bottom: 0.5rem;
	}
	.contact-wrapper .p-button:not(.p-button-icon-only) {
		display: flex;
		width: 100%;
	}
	.contact-wrapper .p-buttonset .p-button {
		margin-bottom: 0;
	}
}

.work .p-card {
	border-radius: 0;
}

.work .p-card .p-card-body {
	flex: 1;
}

.work .secondary {
	background-color: #e7e7e7;
	color: black;

	&.p-card .p-card-subtitle {
		color: #787878;
	}
}
